import React from 'react';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import SideNavbar from '../Sidenav/SideNavbar';
import table from '../../Styles/Table.module.css';
import Navbar from '../../components/Navbar/Navbar'
function App() {

  // const [financeres, setfinanceresponse] = useState('');
  const { state } = useLocation();
  const data = state.customer.results;
  // function onFinance() {


  //   console.log("ddddddddd", value);
  //   console.log("ddddddddd111111111", value1);
  //   // let data ={projects}  
  //   fetch("https://lpcapp.adqservices.com/api/customer/customer_project_status", {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       "project_name": value,
  //       "units": value1
  //     })
  //   }).then((result) => {
  //     result.json().then((resp) => {
  //       setfinanceresponse(resp);
  //     })

  //   })
  // }
  // console.warn("fffffffffffffff", financeres)

  // const getInitialState = () => {
  //   const value = data[0].project_name;
  //   return value;
  // };
  // const [value, setValue] = useState(getInitialState);
  // const handleChange = (e) => {
  //   setValue(e.target.value);
  // };

  // const unitsindex = data.findIndex(obj => obj.project_name === value);

  // const getInitialState1 = () => {
  //   const value1 = data[unitsindex].units;
  //   return value1;
  // };
  // const [value1, setValue1] = useState(getInitialState1);
  // const handleChange1 = (e) => {
  //   setValue1(e.target.value);
  // };


  // const navigate = useNavigate();
  // const handleNavlogout = () => {
  //   navigate('/Customerlogin')
  // }
  return (

    <div>
    <Navbar value='projectstatus' />
      <div >

        <div className='row row-cols-2'>
          <div className='col-6 col-md-3'>

            <SideNavbar />
          </div>
          <div className='col-12 col-md-9 '>
            <center>
              <table className={table.projectstatustable} >

                <tr className={table.projectstatustr}  >

                  <th >Project Name</th>
                  <th >Units</th>
                  <th >Project Type</th>
                  <th >Start Date</th>
                  <th >End Date</th>
                  <th>ETA</th>
                  <th>Project Status</th>
                </tr>
                {
                  data.map((item, i) =>
                    <tr key={i} className={table.fintr}>
                      <td>{item.project_name}</td>
                      <td>{item.units}</td>
                      <td>{item.project_type}</td>
                      <td>{item.start_date}</td>
                      <td>{item.actual_end_date}</td>
                      <td>{item.date_to_complete}</td>
                      <td>{item.project_status}</td>

                    </tr>
                  )}

              </table>
            </center>
          </div>

        </div>
      </div>
    </div>
  );
}

export default App;

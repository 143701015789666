
import React, {  useState } from "react"
import './Emailverfication.css'
import { useNavigate } from "react-router-dom";
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";

export default function SignUp(props) {


    const [customer_country, setCountry] = React.useState('');
    const [customer_district, setDistrict] = React.useState('');
    const [customer_state, setState] = React.useState('');
    const [customer_address, setAddress] = React.useState('');
    const [customer_pincode, setpincode] = React.useState('');
    // const [customer_Type, settype] = React.useState('');
    const [customer_pannumber, setpannumber] = React.useState('');
    const [aadhar_number, setadharnumber] = React.useState('');
    const [customer_gstin, setgstin] = React.useState('');

    const navigate = useNavigate()

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async () => {

        let result = await fetch('https://lpcapp.adqservices.com/api/customer/customersignup2', {
            method: 'post',
            body: JSON.stringify({
                customer_country, customer_district, customer_state,
                customer_address, "customer_Type": value, customer_pincode, customer_pannumber, customer_gstin,
            }),

            headers: {
                'Content-Type': 'application/json'
            }
        });
        result = await result.json();
        console.warn(";lknjbvcxzsaerdtfghjkliuytfrdetyghuiytresertyuihygfd", result);

        if (result.status === 404){
            alert("Email/Mobile Number already exists");
            }
            else if (result.status === 500){
                alert("Please enter all the required fields")
              } 
              else if (result.status === 200){
                alert("Successfully Registered")
                 navigate('/Customerlogin')
              }

    }

    console.log(window.location.href.split("Emailverfication?")[1])
    // const customer_email = window.location.href.split("Emailverfication?")[1]
    // console.log(customer_Type, '++++++++$$$$$$$$$$$')



    const getInitialState = () => {
        const value = "";
        return value;
    };

    const [value, setValue] = useState(getInitialState);

    const handleChange = (e) => {
        setValue(e.target.value);
    };
    console.log(value, "typee")
    return (
        <div className="row" style={{marginTop:60}}> 
        <div className="col-3"></div>   
        <div className="col-6">
        <Form onSubmit={handleSubmit(onSubmit)} className="Auth-form1">
        <div className="Auth-form-content">
        <h3 className="Auth-form-title1" style={{fontWeight:700}}>Email Verfication </h3>
                    <div className="form-group mt-3">
                <Form.Field>
                    <div className="row">
                        <div className="col-3">
                        <label style={{color:"#00008b"}}>Country<sup style={{color:'red'}}>*</sup>:</label><br/>
                        </div>

                        <div className="col-9"> 
                     <input
                        className="form-control mt-1"
                        type="text"
                        {...register("customer_country", { required: true, maxLength: 10 })}
                        onChange={(e) => setCountry(e.target.value)}
                        value={customer_country}
                    />
                        </div>
                    </div>
                   
                </Form.Field>
                </div>
                {errors.customer_country && <div style={{color:'red'}}>Please enter the Country</div>}

                <div className="form-group mt-3">
                <Form.Field>
                <div className="row">
                        <div className="col-3">
                        <label style={{color:"#00008b"}}>District<sup style={{color:'red'}}>*</sup>:</label><br/>
                        </div>
                        <div className="col-9">
                        <input
                        className="form-control mt-1"
                        type="text"
                        {...register("customer_district", { required: true, maxLength: 10 })}
                        onChange={(e) => setDistrict(e.target.value)}
                        value={customer_district}
                    />
                        </div>
                    </div>
                    
                  
                </Form.Field>
                </div>
                {errors.customer_district && <div style={{color:'red'}}>Please enter the District</div>}

                <div className="form-group mt-3">
                <Form.Field>
                <div className="row">
                        <div className="col-3">
                        <label style={{color:"#00008b"}}>State<sup style={{color:'red'}}>*</sup>:</label><br/>
                        </div>
                        <div className="col-9">
                        <input
                        className="form-control mt-1"
                        type="text"
                        {...register("customer_state", { required: true, maxLength: 10 })}
                        onChange={(e) => setState(e.target.value)}
                        value={customer_state}
                    />
                        </div>
                    </div>
                    
                   
                </Form.Field>
                </div>
                {errors.customer_state && <div style={{color:'red'}}>Please enter the State</div>}

                <div className="form-group mt-3">
                <Form.Field>
                <div className="row">
                        <div className="col-3">
                        <label style={{color:"#00008b"}}>Address<sup style={{color:'red'}}>*</sup>:</label><br/>
                        </div>
                        <div className="col-9">
                        <input
                        className="form-control mt-1"
                        type="text"
                        {...register("customer_address", { required: true, maxLength: 10 })}
                        onChange={(e) => setAddress(e.target.value)}
                        value={customer_address}
                    />
                        </div>
                    </div>
                
                    
                </Form.Field>
                </div>
                {errors.customer_address && <div style={{color:'red'}}>Please enter the Address</div>}

                <div className="form-group mt-3">
                <Form.Field>
                <div className="row">
                        <div className="col-3"> 
                        <label style={{color:"#00008b"}}>Pin Code<sup style={{color:'red'}}>*</sup>:</label><br/>
                        </div>
                        <div className="col-9">
                        <input
                        className="form-control mt-1"
                        type="text"
                        {...register("customer_pincode", { required: true, maxLength: 10 })}
                        onChange={(e) => setpincode(e.target.value)}
                        value={customer_pincode}
                    />
                        </div>
                    </div>
                    
                    
                </Form.Field>
                </div>
                {errors.customer_pincode && <div style={{color:'red'}}>Please enter the Pincode</div>}
                     
                <div className="form-group mt-3">
                    <div className="row">
                        <div className="col-3">
                        <label style={{color:"#00008b"}}>Type<sup style={{color:'red'}}>*</sup>:</label>
                        </div>
                        <div className="col-9">
                        <select value={value} onChange={handleChange} style={{width:390,height:35}}>
                            <option value="0">Select Type </option>
                            <option value="Individual">Individual</option>
                            <option value="Company" onChange={handleChange}>Company</option>
                        </select>
                        </div>
                    </div>
                        
                        
                     
                    </div>
                    
              {value && value==="Company" &&
                   <div className="form-group mt-3">
                   <Form.Field>
                    <div className="row">
                        <div className="col-3">
                        <label style={{color:"#00008b"}}>GSTIN<sup style={{color:'red'}}>*</sup>:</label><br/>
                        </div>
                        <div className="col-9">
                        <input
                           className="form-control mt-1"
                           type="text"
                           {...register("customer_gstin", { required: true, maxLength: 10 })}
                           onChange={(e) => setgstin(e.target.value)}
                           value={customer_gstin}
                       />
                        </div>
                    </div>
                   </Form.Field>
                   </div>
                  }
                   {errors.customer_gstin && <div style={{color:'red'}}>Please enter the GSTIN</div>}

                   <div className="form-group mt-3">
                <Form.Field>
                <div className="row">
                        <div className="col-3"> 
                        <label style={{color:"#00008b",fontSize:15}}>Pan Number<sup style={{color:'red'}}>*</sup>:</label><br/>
                        </div>
                        <div className="col-9">
                        <input
                        className="form-control mt-1"
                        type="text"
                        {...register("customer_pannumber", { required: true, maxLength: 10 })}
                        onChange={(e) => setpannumber(e.target.value)}
                        value={customer_pannumber}
                    />
                        </div>
                    </div>
                    
                    
                </Form.Field>
                </div>
                {errors.customer_pannumber && <div style={{color:'red'}}>Please enter the Pan Number</div>}
                <div className="form-group mt-3">
                <Form.Field>
                <div className="row">
                        <div className="col-3"> 
                        <label style={{color:"#00008b",fontSize:15}}>Adhar Number<sup style={{color:'red'}}>*</sup>:</label><br/>
                        </div>
                        <div className="col-9">
                        <input
                        className="form-control mt-1"
                        type="text"
                        {...register("aadhar_number", { required: true, maxLength: 10 })}
                        onChange={(e) => setadharnumber(e.target.value)}
                        value={aadhar_number}
                    />
                        </div>
                    </div>
                    
                    
                </Form.Field>
                </div>
                {errors.customer_pannumber && <div style={{color:'red'}}>Please enter the Pan Number</div>}
                <div className="row">
                    <div className="col-5"></div>
                    <div className="col-2">
                    <Button className='btn btn-success mt-3' type='submit' style={{textAlign:'center'}} >Submit</Button>
                    </div>
                    <div className="col-5"></div>
                </div>
                 
                </div>
            </Form>
            </div>
            <div className="col-3"></div>
        </div>

    )


}
import React from 'react'
import './Forgotpassword.css'

const Home = () => {
   
    //   const [user_email,setEmail]=React.useState('');
    //   const [Password,setPassword]= React.useState('');


    return (
        <div className="Auth-container" style={{backgroundColor:'white'}}>
            <form className="Auth-form">
              <div className="Auth-form-content">
                <div className="content">
                    <h3 className="Auth-form-title" >Forgot Password</h3>
                    <div className="form-group mt-8">
                        <label style={{color:'black'}}>Email Address</label>
                        <input
                            type="email"
                            className="form-control mt-1"
                            placeholder="Enter Registered Email"
                        // onChange={(e)=>setEmail(e.target.value)}

                        />
                    </div>
                    <div className="form-group mt-2">
                        <label style={{color:'black'}}>New Password</label>
                        <input
                        style={{width:300}}
                            type="password"
                            className="form-control mt-1"
                            placeholder="Ener Password"
                        // onChange={(e)=>setPassword(e.target.value)}
/>  
                    </div>
                    <div className="form-group mt-2">
                        <label style={{color:'black'}}>Confirm New Password</label>
                        <input
                        style={{width:300}}
                            type="password"
                            className="form-control mt-1"
                            placeholder="Ener Password"
                        // onChange={(e)=>setPassword(e.target.value)}

                        />
                    </div>
                    <div className="row">
                        <div className="d-grid gap-2 mt-3" style={{ marginLeft: 15}}>
                            {/* <button type="button" className="btn btn-primary" >
              Submit
            </button> */}
                        </div>
                       
                        <div className=" mt-4">
                            <button type="submit" className="btn btn-secondary" >
                                Submit
                            </button>
                        </div>
                      
                    </div>
                    {/* <p className="forgot-password text-right mt-2">
            Forgot <a href="#">password?</a>
          </p> */}
                </div>
                </div>
            </form>


            {/* <Table  bordered hover style={{width:1000,marginLeft:250,marginTop:50,height:300}}>
      <thead style={{backgroundColor:'gray',color:'white',height:50}}>
        <tr>
          <th>#</th>
          <th style={{width:450}}>Project Name</th>
          <th style={{width:450}}>Project Details</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Project 1</td>
          <td>High Level Details</td>
        </tr>
        <tr>
        <td>2</td>
          <td>Project 2</td>
          <td>High Level Details</td>
        </tr>
        <tr>
        <td>3</td>
          <td>Project 3</td>
          <td>High Level Details</td>
        </tr>
        <tr>
        <td>4</td>
          <td>Project 4</td>
          <td>High Level Details</td>
        </tr>
        <tr>
        <td>5</td>
          <td>Project 5</td>
          <td>High Level Details</td>
        </tr>
        
      </tbody>
    </Table> */}
        </div>
    )
}

export default Home



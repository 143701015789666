import React, { useEffect, useState } from 'react';
import { Container, Header } from 'rsuite';
import Carousel from 'react-grid-carousel'
import Card from 'react-bootstrap/Card';
import Navbar from '../../components/Navbar/Navbar';
import SideNavbar from '../Sidenav/SideNavbar';
import '../../components/Home/Home.css';
import { useLocation } from 'react-router-dom';
import aggregate from '../../images/aggregate.jpg';
import Marble from '../../images/marble.jpg';
import Plastic from '../../images/plastic2.jpg';
import Sand from '../../images/sand.jpg';
import Steel from '../../images/steel3.jpg';
import Wood from '../../images/wood.jpeg';
import Cement from '../../images/cement.jpg';
import Concrete from '../../images/concrete.jpg';
import Glass from '../../images/glass.jpg';
import Brick from '../../images/brick.jpg';
import Paint from '../../images/paint.jpg';
import { Row, Col } from 'react-bootstrap';


function Unitbundle() {

  const [materialdata, setMaterialdata] = useState('');
  const [searchText, setSearchText] = useState("");

  const { state } = useLocation();

  const data = state.payments.paymentresult;

  const projectdetails = state.customer.results

  const userid = projectdetails[0].userid


  const [toppings, setToppings] = useState([])

  useEffect(() => {
    fetch("https://lpcapp.adqservices.com/api/unitbundles/unit_material").then((result) => {
      result.json().then((resp) => {

        setToppings(resp.unitmaterial)

      })
    })
  }, [])

  const [datas, setDatas] = useState(toppings);

  const [checked, setChecked] = useState([]);

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const checkedItems = checked.length
    ? checked.reduce((total, item) => {
      return total + ", " + item;
    })
    : "";
  console.log("checkkkkdd", checkedItems)

  var isChecked = (item) =>
    checked.includes(item) ? "checked-item" : "not-checked-item";

  function onUnitbundle() {

    fetch("https://lpcapp.adqservices.com/api/unitbundles/user_selected", {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "userid": userid,
        "project_name": value,
        "project_unit": value1,
        "material_type": "Sand",
        "material_name": checkedItems
      })
    }).then((result) => {
      result.json().then((resp) => {
        setMaterialdata(resp);
      })

    })
    if (materialdata.status === 200) {
      alert(materialdata.message);

    }
  }
  console.log("puttt dataaaa", materialdata)
  const getInitialState = () => {
    const value = data[0].project_name;
    return value;
  };
  const [value, setValue] = useState(getInitialState);
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const unitsindex = data.findIndex(obj => obj.project_name === value);
 



  const getInitialState1 = () => {
    const value1 = data[unitsindex].units;
    return value1;
  };
  const [value1, setValue1] = useState(getInitialState1);
  const handleChange1 = (e) => {
    setValue1(e.target.value);
  };

  const excludeColumns = [""];

  const handleChangesearch = value => {
    setSearchText(value);
    filterData(value);
  };


  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setDatas(toppings);

    else {
      const filteredData = toppings.filter(item => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setDatas(filteredData);
    }
  }
  console.log("fffilteerr unitttttttt", datas)
  return (
    <div>
      <Header>
        <Navbar value='unitbundle' />
      </Header>

      <div className='row row-cols-2'>
        <div className='col-6 col-md-3'>

          <SideNavbar />
        </div>
        <div className='col-12 col-md-9 '>

          <Container>

            <div className='row mt-2'>

              <Row>

                <Col sm={6}>
                  <b style={{ fontFamily: 'bold' }}>Search</b >: <input
                    style={{fontFamily:'bold'}}
                    type="text"
                    placeholder="search for material units..."
                    value={searchText}
                    onChange={e => handleChangesearch(e.target.value)}
                  />
                </Col>
                <Col sm={6}>
                </Col>
              </Row>
              <div className='mt-2'>
                <Carousel cols={4} rows={1} gap={10} loop className='mt-3'>
                  <Carousel.Item>
                    <img alt="" width="100%" height="90%" src={aggregate} />
                    <center><b style={{ fontFamily: 'bold' }}>Aggregate</b></center>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img alt="" width="100%" height="90%" src={Brick} />
                    <b style={{ fontFamily: 'bold' }}><center>Brick</center></b>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img alt="" width="100%" height="90%" src={Cement} />
                    <b style={{ fontFamily: 'bold' }}><center>Cement</center></b>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img alt="" width="100%" height="90%" src={Concrete} />
                    <b style={{ fontFamily: 'bold' }}><center>Concrete</center></b>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img alt="" width="100%" height="90%" src={Glass} />
                    <b style={{ fontFamily: 'bold' }}><center>Glass</center></b>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img alt="" width="100%" height="90%" src={Marble} />
                    <b style={{ fontFamily: 'bold' }}><center>Marble</center></b>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img alt="" width="100%" height="90%" src={Paint} />
                    <b style={{ fontFamily: 'bold' }}><center>Paint</center></b>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img alt="" width="100%" height="90%" src={Plastic} />
                    <b style={{ fontFamily: 'bold' }}><center>Plastic</center></b>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img alt="" width="100%" height="90%" src={Sand} />
                    <b style={{ fontFamily: 'bold' }}><center>Sand</center></b>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img alt="" width="100%" height="90%" src={Steel} />
                    <b style={{ fontFamily: 'bold' }}><center>Steel</center></b>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img alt="" width="100%" height="90%" src={Wood} />
                    <b style={{ fontFamily: 'bold' }}><center>Wood</center></b>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>

            {datas.length>0 && <div className="data-container mt-1">
              <Carousel cols={2} rows={1} gap={12} loop className="data-carousel-container ">
                {
                  datas.map((topping, index) =>
                    <Carousel.Item className="data-carousel-item">
                      <div >
                        <Card style={{ width: '100%' }}>

                          <Card.Text style={{ textAlign: 'center' }}>

                            <div style={{ textAlign: 'center' }}>
                              <div className='row' >
                                <center><b >Material Name: {topping.material_type}</b></center>
                                <div className='col-sm-7 pb-1' style={{ textAlign: 'start' }}>
                                  <div key={index}>

                                    <div className='mt-2'>
                                      <b>Material Types</b>
                                      {topping.material_name.map((subItems, sIndex) => {

                                        if (toppings[index].material_type === 'Aggregate') {
                                          return <div>
                                              <div>
                                                <input value={subItems} type="checkbox" onChange={handleCheck} />
                                                <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                              </div>
                                            
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Sand') {
                                          return <div >
                                            <div>
                                              <input value={subItems} type="checkbox" onChange={handleCheck} />
                                              <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                            </div>
                                          </div>
                                        }
                                        else if (toppings[index].material_type === 'Brick') {
                                          return <div >
                                            <div>
                                              <input value={subItems} type="checkbox" onChange={handleCheck} />
                                              <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                            </div>
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Concrete') {
                                          return <div >
                                            <div>
                                              <input value={subItems} type="checkbox" onChange={handleCheck} />
                                              <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                            </div>
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Glass') {
                                          return <div >
                                            <div>
                                              <input value={subItems} type="checkbox" onChange={handleCheck} />
                                              <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                            </div>
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Marble') {
                                          return <div >
                                            <div>
                                              <input value={subItems} type="checkbox" onChange={handleCheck} />
                                              <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                            </div>
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Paint') {
                                          return <div >
                                            <div>
                                              <input value={subItems} type="checkbox" onChange={handleCheck} />
                                              <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                            </div>
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Plastic') {
                                          return <div >
                                            <div>
                                              <input value={subItems} type="checkbox" onChange={handleCheck} />
                                              <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                            </div>
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Wood') {
                                          return <div >
                                            <div>
                                              <input value={subItems} type="checkbox" onChange={handleCheck} />
                                              <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                            </div>
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Steel') {
                                          return <div >
                                            <div>
                                              <input value={subItems} type="checkbox" onChange={handleCheck} />
                                              <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                            </div>
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Cement') {
                                          return <div >
                                            <div>
                                              <input value={subItems} type="checkbox" onChange={handleCheck} />
                                              <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                            </div>
                                          </div>
                                        }
                                        else {
                                          return {};
                                          //return something here.
                                       }

                                      })}
                                    </div>
                                  </div>
                                </div>
                                <div className='col'>
                                  <div key={index} >

                                    <div className='mt-2'>
                                      <b>Price</b>
                                      {topping.material_price.map((subItems, sIndex) => {

                                        if (toppings[index].material_type === 'Aggregate') {

                                          return <div>
                                              <div className='con'>{subItems}</div>

                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Sand') {

                                          return <div >

                                            {subItems}
                                          </div>
                                        }
                                        else if (toppings[index].material_type === 'Brick') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Concrete') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Glass') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Marble') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Paint') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Plastic') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Wood') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Steel') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Cement') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }
                                        else {
                                          return {};
                                          //return something here.
                                       }

                                      })}
                                    </div>
                                  </div>
                                </div>
                                <div className='col'>
                                  <div key={index}>

                                    <div className='mt-2'>
                                      <b>Metrics</b>
                                      {topping.material_metric.map((subItems, sIndex) => {

                                        if (toppings[index].material_type === 'Aggregate') {

                                          return <div>
                                              <div className=''>{subItems}</div>
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Sand') {

                                          return <div >

                                            {subItems}
                                          </div>
                                        }
                                        else if (toppings[index].material_type === 'Brick') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Concrete') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Glass') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Marble') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Paint') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Plastic') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Wood') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Steel') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Cement') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }
                                        else {
                                          return {};
                                          //return something here.
                                       }

                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>

                          </Card.Text>
                        </Card>
                      </div>
                    </Carousel.Item>
                  )}

              </Carousel>
            </div>}

            {datas.length===0 && <div className="data-container mt-1">
              <Carousel cols={2} rows={1} gap={12} loop className="data-carousel-container ">
                {
                  toppings.map((topping, index) =>
                    <Carousel.Item className="data-carousel-item">
                      <div >
                        <Card style={{ width: '100%' }}>
                          <Card.Text style={{ textAlign: 'center' }}>

                            <div style={{ textAlign: 'center' }}>
                              <div className='row' >
                                <center><b >Material Name: {topping.material_type}</b></center>
                                <div className='col-sm-7 pb-1' style={{ textAlign: 'start' }}>
                                  <div key={index}>

                                    <div className='mt-2'>
                                      <b>Material Types</b>
                                      {topping.material_name.map((subItems, sIndex) => {

                                        if (toppings[index].material_type === 'Aggregate') {
                                          return <div>
                                              <div>
                                                <input value={subItems} type="checkbox" onChange={handleCheck} />
                                                <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                              </div>
                                            
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Sand') {
                                          return <div >
                                            <div>
                                              <input value={subItems} type="checkbox" onChange={handleCheck} />
                                              <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                            </div>
                                          </div>
                                        }
                                        else if (toppings[index].material_type === 'Brick') {
                                          return <div >
                                            <div>
                                              <input value={subItems} type="checkbox" onChange={handleCheck} />
                                              <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                            </div>
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Concrete') {
                                          return <div >
                                            <div>
                                              <input value={subItems} type="checkbox" onChange={handleCheck} />
                                              <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                            </div>
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Glass') {
                                          return <div >
                                            <div>
                                              <input value={subItems} type="checkbox" onChange={handleCheck} />
                                              <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                            </div>
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Marble') {
                                          return <div >
                                            <div>
                                              <input value={subItems} type="checkbox" onChange={handleCheck} />
                                              <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                            </div>
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Paint') {
                                          return <div >
                                            <div>
                                              <input value={subItems} type="checkbox" onChange={handleCheck} />
                                              <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                            </div>
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Plastic') {
                                          return <div >
                                            <div>
                                              <input value={subItems} type="checkbox" onChange={handleCheck} />
                                              <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                            </div>
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Wood') {
                                          return <div >
                                            <div>
                                              <input value={subItems} type="checkbox" onChange={handleCheck} />
                                              <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                            </div>
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Steel') {
                                          return <div >
                                            <div>
                                              <input value={subItems} type="checkbox" onChange={handleCheck} />
                                              <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                            </div>
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Cement') {
                                          return <div >
                                            <div>
                                              <input value={subItems} type="checkbox" onChange={handleCheck} />
                                              <span className={isChecked(subItems)} style={{ padding: '0.5rem' }}>{subItems}</span>
                                            </div>
                                          </div>
                                        }
                                        else {
                                          return {};
                                          //return something here.
                                       }

                                      })}
                                    </div>
                                  </div>
                                </div>
                                <div className='col'>
                                  <div key={index} >

                                    <div className='mt-2'>
                                      <b>Price</b>
                                      {topping.material_price.map((subItems, sIndex) => {

                                        if (toppings[index].material_type === 'Aggregate') {

                                          return <div>
                                              <div className='con'>{subItems}</div>

                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Sand') {

                                          return <div >

                                            {subItems}
                                          </div>
                                        }
                                        else if (toppings[index].material_type === 'Brick') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Concrete') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Glass') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Marble') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Paint') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Plastic') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Wood') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Steel') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Cement') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }
                                        else {
                                          return {};
                                          //return something here.
                                       }
                                      })}
                                    </div>
                                  </div>
                                </div>
                                <div className='col'>
                                  <div key={index}>

                                    <div className='mt-2'>
                                      <b>Metrics</b>
                                      {topping.material_metric.map((subItems, sIndex) => {

                                        if (toppings[index].material_type === 'Aggregate') {

                                          return <div>
                                              <div className=''>{subItems}</div>
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Sand') {

                                          return <div >

                                            {subItems}
                                          </div>
                                        }
                                        else if (toppings[index].material_type === 'Brick') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Concrete') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Glass') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Marble') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Paint') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Plastic') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Wood') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Steel') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }

                                        else if (toppings[index].material_type === 'Cement') {
                                          return <div >
                                            {subItems}
                                          </div>
                                        }
                                        else {
                                          return {};
                                          //return something here.
                                       }

                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>

                          </Card.Text>
                  
                        </Card>
                      </div>
                    </Carousel.Item>
                  )}

              </Carousel>
            </div>}

            <div class="row mt-4 ">
              <div class="col">
                <div className="form-group col-md-4">
                  <div className='row'>
                    <div className='col-6'>
  
                    </div>
                    <div className='col-6'>
                      <div>
                        <select name="projects" className="dropdown" id="projects" defaultValue="select project" value={value} onChange={handleChange}
                        >

                          {data.map(({ project_name }, index) =>

                            <option value={project_name} >{project_name}</option>,
                            console.log("clickedprojecttt", { value }),
                            console.log("index", data.findIndex(obj => obj.project_name === value)),
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div className="form-group col-md-4">
                  <div className='row'>
                    <div className='col-6 '>
                    </div>
                    <div className='col-6 '>
                      <select name="projects" className="dropdown" id="units" value={value1} onChange={handleChange1}>
                        <option value="0" selected>--Select units--</option>
                    
                        {
                          data[unitsindex].units.map((option, index) =>
                            <option value={option}>{option}</option>,
                            console.log("unitsss multi", data[unitsindex].units)
                          )
                        }
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col">
                <div>
                  {
                    <button className="submit" disabled={checkedItems === false} onClick={onUnitbundle}><b>Save</b></button>}
                </div>
                <div>
                  <span style={{ color: 'red' }}>* </span><b style={{ fontFamily: 'bold' }}>Save after selecting the required Materials Types</b>
                </div>
              </div>

            </div>
          </Container>


        </div>

      </div>
    </div>

  );

}
export default Unitbundle;
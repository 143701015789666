import React, { useState }  from "react";
import { Form, Button } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';

function Paynow(){

    const { state } = useLocation();

    
    const [paydata, setpaydata] = useState('');

    const payprojectname = state.project_name


    const payprojectunits = state.project_unit


    const payid = state.id
  

    const payamount = state.balance
   
    const current = new Date();
    const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;

    const handleNav = () => {
 
        
        fetch("https://lpcapp.adqservices.com/api/customer/payment_update", { 
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "paid":payamount,
              "project_name": payprojectname,
              "project_unit": payprojectunits,
              "id": payid,
              "paid_date":date
            })
          }).then((result) => {
            result.json().then((resp) => {
              setpaydata(resp);
            })
      
          })
        }
     console.log("pppppayy",paydata)
      
    if (paydata.status === 200){
        alert(paydata.message);
       
    }
    
return(
    <div className="row mt-5">
        <div className="col-12">
        <div className='col-6'>   
            <Form  className="Auth-form1">
            <div className="Auth-form-content">
             <h6 className="Auth-form-title1" >Pay Here:{state.balance}</h6>
   
            <div className="form-group mt-3">
                <Form.Field>
                    <div className='row'>
                        <div className='col-3'>

                        </div>
                        <div className='col-9'>
                        
                        </div>
                    </div>
                   
                </Form.Field>
                </div>
              

       
                <div className="row">
                    <div className="col-5"></div>
                    <div className="col-2">
                    <Button className='btn btn-success mt-3' type='submit' style={{width:80,height:40, borderRadius:100}}
                     onClick={() => handleNav()}>Paynow</Button>
                    </div>
                    {paydata &&
                    <div className="row" >
                        <div className="col-4"></div>
                        <div className="col-5 mt-3">
                        <h6>{paydata.message}</h6>
                        </div>
                    </div>
                   }
                </div>
                </div>
            </Form>
            </div>
        </div>
    </div>
)
}
export default Paynow; 
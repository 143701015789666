import React from 'react';
import './SideNavbar.css';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import nav from '../../Styles/Navbar.module.css';
import { AiFillHome } from "react-icons/ai";
import { MdPayment } from "react-icons/md";
import { AiFillProject } from "react-icons/ai";
import { AiFillAccountBook } from "react-icons/ai";
import { SiMaterialdesign } from "react-icons/si";

import { Sidenav, Nav } from 'rsuite';
import "rsuite/dist/rsuite.min.css";

function Navbar() {
  

  const { state } = useLocation();
 
  // console.log(data[0], '@@@@')
  console.log("Navvvvvvvv ", state)
 


  const navigate = useNavigate();
  const handleNav = () => {
    navigate('/CustomerHome', { state: state })
  }

  const handleNavFinance = () => {
    navigate('/Finance', { state: state })

  }
 
  const handleNavProjectstatus = () => {
    navigate('/Projectstatus', { state: state })

  }
  const handleNavPayments = () => {
    navigate('/Samplepayments', { state: state })
  }
  const handleNavUnit = () => {
    navigate('/Unitbundle', { state: state })
  }
  return (
    
    
  <div >
  <Sidenav defaultOpenKeys={['3', '4']} style={{height:'100%'}} >
    <Sidenav.Body >
      <Nav activeKey="1"  className='mt-5'>
      
      <Nav.Item  eventKey="2"   onClick={handleNav} >
          <span className={nav.navstyle}><span className='p-3'><AiFillHome/></span> Customer Profile</span>
        </Nav.Item>
        <Nav.Item eventKey="2"  onClick={handleNavProjectstatus} >
          <span className={nav.navstyle}><span className='p-3'><AiFillProject/></span>Project Status</span>
        </Nav.Item>
        <Nav.Item eventKey="2"  onClick={handleNavFinance} >
         <span className={nav.navstyle}><span className='p-3'><AiFillAccountBook/></span> Finance</span>
        </Nav.Item>
        <Nav.Item eventKey="2" onClick={handleNavPayments}>
          <span className={nav.navstyle}><span className='p-3'><MdPayment/></span> Payments</span>
        </Nav.Item>
        <Nav.Item eventKey="2" onClick={handleNavUnit}>
          <span className={nav.navstyle} ><span className='p-3'><SiMaterialdesign/></span> Unit Bundle</span>
        </Nav.Item>   
      </Nav>
    </Sidenav.Body>
  </Sidenav>
</div>
  );
}

export default Navbar;
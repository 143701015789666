import './Home.css'
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Navbar from '../../components/Navbar/Navbar';
import { Container, Header, Content } from 'rsuite';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import p2 from '../../images/p2.jpg';
import p15 from '../../images/p15.jpg';
import p18 from '../../images/p18.jpg';
import ListGroup from 'react-bootstrap/ListGroup';
import styles from '../../Styles/Card.module.css';

export function Home() {

  const [data, setData] = useState([])
  useEffect(() => {
    fetch("https://lpcapp.adqservices.com/api/home/project").then((result) => {
      result.json().then((resp) => {
        setData(resp)
        console.warn("befor dataaaaaa",data)
      })
    })
  })
  console.warn(data)

  return (
    <center>
    <div>
      <Header >
        <Navbar />
      </Header>

      <Container>

        <Content>
          <div className='row mt-3'>
            <Carousel variant="dark">
              <Carousel.Item>
                <img
                  className="d-block w-100 "
                  style={{ height: 400 }}
                  src={p2}
                  alt="First slide"
                />
                <Carousel.Caption >
                  <h1 style={{ color: 'yellow', fontFamily: 'cursives' }}>We Are True Builders</h1>
                  <h4 style={{ color: 'white', fontFamily: 'cursives' }}>Nothing is on built on stone; all is build on sand, but we must build as if sand were stone</h4>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 "
                  style={{ height: 400 }}
                  src={p15}
                  alt="Second slide"
                />
                <Carousel.Caption>
                  <h1 style={{ color: 'yellow', fontFamily: 'cursives' }}>We Construct,Create</h1>
                  <h4 style={{ color: 'white', fontFamily: 'cursives' }}>You can dream,design, and build the most wonderful place in the world. But it requires people to make the dream a reality</h4>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  style={{ height: 400 }}
                  src={p18}
                  alt="Third slide"
                />
                <Carousel.Caption >
                  <h1 style={{ color: 'yellow', fontFamily: 'cursives' }}>Construction Service</h1>
                  <h4 style={{ color: 'white', fontFamily: 'cursives' }}>We deliver construction management services to customer from planning stage to completion stage</h4>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>

          <div className='row mt-3'>
            <Card>
              <Card.Body>
                <Card.Text>
                  <Row>
                    <Col style={{color:'black'}} >
                      <div ><h2 >We Shape Our Buildings,</h2></div>
                      <div><h2>thereafter, They Shape Us...</h2></div>
                      <div>
                        <h6 className='mt-4' style={{ textAlign: 'justify',fontFamily:'Times New Roman' }}>The construction sector is visualized to play a powerful role in economic growth, in addition to producing structures
                          that adds to productivity and quality of life. economic development is a term that economics politician and other have
                          used frequently in the 20th century, modernization westernization and specially industrialisation are other terms people
                          have used while discussing economic development. economic development has a direct relationship with the environment.
                          government undertaking to meet go abroad economic objectives such as price stability, high employment and sustainable
                          growth, such efforts include financial and economic policies, regulations of financial industry trade and tax policies.</h6>
                      </div>
                    </Col>
                    <Col >
                      <Card.Img src="https://wallpaperaccess.com/full/3434349.jpg" alt="" style={{ width: 400, height: 300 }} />
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
            <br />
            <Card>

            </Card>
          </div>

          <div className='row'>
            <h3  style={{color:'black',fontFamily:'bold'}}>WHAT WE HAVE</h3>
          </div>

          <div className='row'>
           <h4 style={{color:'black',fontFamily:'bold'}}>We are true builders with fours specalized Business Groups</h4>
          </div>

          <div class="row row-cols-4" >
        <div class="col">
          <img src="https://is1-3.housingcdn.com/4f2250e8/2bd7d029205da7378360c74d099ca124/v0/medium.jpg" alt='' width={320} height={200} />
          <div><h5 className="container-img" style={{fontFamily: 'cursives',color:'black'}} >Apartment</h5></div>
        </div>
        <div class="col"  >
          <img src="https://is1-2.housingcdn.com/01c16c28/1c2eec6a18624d0d18f368a470c372f2/v0/medium/residential_plot-for-sale-zaheerabad-Hyderabad-plot_view.jpg" alt='' width={320} height={200} />
          <div><h5 className="container-img" style={{fontFamily: 'cursives',color:'black'}} >Plots</h5></div>
        </div>
        <div class="col">
          <img src="https://assets-news.housing.com/news/wp-content/uploads/2022/02/27121904/featured-compressed-67.jpg" alt='' width={320} height={200} />
          <div><h5 className="container-img" style={{fontFamily: 'cursives',color:'black'}} >Villa</h5></div>
        </div>
        <div class="col">
          <img src="https://media.istockphoto.com/photos/modern-open-plan-office-space-interior-picture-id1352854006?b=1&k=20&m=1352854006&s=170667a&w=0&h=TYkpYsIne13o4rjOYM1_Hee_oW_EgoddXSAvK1KFgpE=" alt='' width={320} height={200} />
          <div><h5 className="container-img" style={{fontFamily: 'cursives',color:'black'}} >Office Spaces</h5></div>
        </div>
      </div>

      <div className='row'>
         <h3 style={{color:'black',fontFamily:'bold'}}>Our Projects</h3>
      </div>

      <div className="container-fluid card-container">
      {
        data.map((item) =>

          <div>

            <div className="row mt-3">
                    
                      <Card  className={styles.projectcard}>
                        <Card.Header style={{backgroundColor:'#8CC0DE',color:''}}><h6>{item.project_name}</h6></Card.Header>
                      <Card.Body >

                 <center>
                 <ListGroup.Item style={{textAlign:"justify"}}>
                    <div className='row'>
                      <div className='col-sm-5 pb-1' style={{textAlign:"justify"}}>
                        <b >Project Type</b>
                      </div>
                      <div className='col-sm-1'>:</div>
 
                      <div className='col-sm-5' style={{textAlign:"justify"}}>
                        {item.project_type}
                      </div>
                    </div>
                  
                  </ListGroup.Item>
                 </center>

                  <ListGroup.Item style={{textAlign:"justify"}}>
                   
                    <div className='row'>
                      <div className='col-sm-5 pb-1' >
                        <b >Location</b>
                      </div>
                      <div className='col-sm-1'>:</div>
                      <div className='col-sm-5' >
                        {item.address}
                      </div>
                    </div>
                  </ListGroup.Item>

                  <ListGroup.Item style={{textAlign:"justify"}}>
                    <div className='row'>
                      <div className='col-sm-5 pb-1'>
                        <b>Dimension</b>
                      </div>
                      <div className='col-sm-1'>:</div>
                      <div className='col-sm-5'>
                        {item.dimension} sq.ft
                      </div>
                    </div>
             
                  </ListGroup.Item>
                     
                        </Card.Body>
      
                    </Card>
                 
              </div>
            </div>

        )
      }
    </div>

        </Content>
  

      </Container>

    </div>
    <br></br><br/>
  </center>
  
  )

}
// import logo from './logo.svg';
import './App.css';
import { BrowserRouter,Routes, Route } from 'react-router-dom';

import {Home} from './components/Home/Home'
import Finance from '../src/components/Finance/Finance';
import Projectstatus from '../src/components/ProjectStatus/Projectstatus';
// import Navbar from './components/Sidenav/Navbar';
import CustomerHome from '../src/components/CustomerHome/CustomerHome';
import Customerlogin from '../src/components/Customerlogin/Customerlogin';
import Customersignup from '../src/components/Customersignup/Customersignup';
import Forgotpassword from '../src/components/ForgotPassword/Forgotpassword';
import Emailverfication from '../src/components/Emailverfication/Emailverfication';
import Samplepayments from '../src/components/SamplePayments/Samplepayments';
import Paynow from './components/PayNow/Paynow';
import Salesdashboard from '../src/components/SalesDashboard/Salesdashboard';
import Unitbundle from '../src/components/Unitbundle/Unitbundle'

function App() {
  return (
 
  
     <BrowserRouter>
     <main >
      <div className='container-fluid'>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/CustomerHome' element={<CustomerHome/>}/>
        <Route path='/Customerlogin' element={<Customerlogin/>} />
        <Route path='/Finance' element={<Finance/>} />
        <Route path='/Projectstatus' element={<Projectstatus/>} />
        <Route path='/Customersignup' element={<Customersignup/>} />
        <Route path='/Forgotpassword' element={<Forgotpassword/>} />
        <Route path='/Emailverfication' element={<Emailverfication/>} />
        <Route path='/Samplepayments' element={<Samplepayments/>} />
        <Route path='/Paynow' element={<Paynow/>} />
        <Route path='/Salesdashboard' element={<Salesdashboard/>} />
        <Route path='/Unitbundle' element={<Unitbundle/>} />
    
      
      </Routes>
      </div>
      </main>
      </BrowserRouter>
  
  );
}

export default App;

import React, { useState } from "react";
// import Css from '../src/components/Css/Navbar';
import SideNavbar from '../Sidenav/SideNavbar';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import './CustomerHome.css'
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Row, Col } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import table from '../../Styles/Table.module.css';
import styles from '../../Styles/Card.module.css';
import Alert from 'react-bootstrap/Alert';
import Navbar from '../../components/Navbar/Navbar'
import Popup from '../Popup';
import { Header } from "rsuite";


export default function App(props) {
  
  // const [homerow, sethomerow] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [tablerow, settablerow] = useState('');
  const [ownerprofile, setownerprofile] = useState('');
  // const [showA, setShowA] = useState(false);
  // const toggleShowA = () => setShowA(!showA);
  // const unitprojectname = homerow.project_name



  // const navigate = useNavigate();

  const { state } = useLocation();
  

  const data = state.customer.results




  const togglePopup = (i) => {
    setIsOpen(!isOpen);

    const inprogressdata = data
  
    const inprogressproject = inprogressdata[i].project_name
  
    const inprogressunits = inprogressdata[i].units
   

    fetch("https://lpcapp.adqservices.com/api/customer/customer_project_status", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "project_name": inprogressproject,
        "units": inprogressunits
      })
    }).then((result) => {
      result.json().then((resp) => {
        settablerow(resp);
      })

    })
  }
  console.log("rowwwwwwwwwwwwwwwww", tablerow)

  const ownerData = (i) => {


    const inprogressdata = data

    const inprogressproject = inprogressdata[i].project_name
 
    const inprogressunits = inprogressdata[i].units
   

    fetch("https://lpcapp.adqservices.com/api/customer/ownerdetails", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "project_name": inprogressproject,
        "project_unit": inprogressunits
      })
    }).then((result) => {
      result.json().then((resp) => {
        setownerprofile(resp);
      })

    })
  }

  // const [value1, setValue1] = useState("");
  // const handleChange1 = (e) => {
  //   setValue1(e.target.value);
  // };
  // console.log("value11111111111111111", value1)
  
  return (

    <div>
     <Header>
        <Navbar value='Customerhome'/>
     </Header>

     <div className='row row-cols-2'>
        <div className='col-6 col-md-3'>
          <SideNavbar />
        </div>
        <div className='col-12 col-md-9 mt-5'>

          <div className="row row-cols-2">

            <div className='col-6 col-md-4'>
              <Card className={styles.custcarddetails}>
                <Card.Header className={styles.customercardheader}>Personal Profile</Card.Header>
                <Card.Body >
                  <ListGroup.Item className="col-12" style={{ fontSize: 14 }}>
                
                  </ListGroup.Item>

                  <ListGroup.Item >
                    <div className='row'>
                      <div className='col-sm-4 pb-1'>
                        <b >Email:</b>
                      </div>
                      <div className='col'>
                        {data[0].customer_email}
                      </div>
                    </div>
                  </ListGroup.Item>

                  <ListGroup.Item >
                    <div className='row'>
                      <div className='col-sm-4 pb-1'>
                        <b >Name:</b>
                      </div>
                      <div className='col'>
                        {data[0].customer_name}
                      </div>
                    </div>

                  </ListGroup.Item>

                  <ListGroup.Item>

                    <div className='row'>
                      <div className='col-sm-4 pb-1'>
                        <b >PhoneNo:</b>
                      </div>
                      <div className='col'>
                        {data[0].customer_mobile}
                      </div>
                    </div>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <div className='row'>
                      <div className='col-sm-4 pb-1'>
                        <b>AdharNo:</b>
                      </div>
                      <div className='col'>
                        {data[0].aadhar_number}
                      </div>
                    </div>
 
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <div className='row'>
                      <div className='col-sm-4'>
                        <b >PanNo:</b>
                      </div>
                      <div className='col'>
                        {data[0].customer_pan_number}
                      </div>
                    </div>

                  </ListGroup.Item>

                </Card.Body>
              </Card>
            </div>

            <div className='col-12 col-md-8 align-self-center'>
              <table>
                <tr className={table.financetable}>
                  <th>Project Name</th>
                  <th >Project Units</th>
                  <th >Location</th>
                  <th >Project Status</th>
                  <th>Details</th>

                </tr>
                {
                  data.map((item, i) =>
                    <tr key={i} style={{ textAlign: 'center' }}>
                      <td>{item.project_name}</td>
                      <td>{item.units}</td>
                      <td>{item.address}</td>

                      <td>
                        <div class="btn btn-link" value="Click to Open Popup"
                          onClick={() => togglePopup(i)}>{item.project_status}</div>
                      </td>
                      <td>
                        
                        {['left'].map((placement) => (
                          <OverlayTrigger
                            trigger="click"

                            placement={placement}
                            overlay={
                              <div id={`popover-positioned-${placement}`}>
                                { ownerprofile &&
                                
                                  <Alert>                            
                                    <Card className={styles.ownercard} >
                                      <Card.Header className={styles.customercardheader}>Owner Info</Card.Header>
                                      <Card.Body >
                                        <ListGroup.Item className="col-12" style={{ fontSize: 14 }}>

                                        </ListGroup.Item>

                                        <ListGroup.Item>
                                          <Row xs="auto">
                                            <Col className="pb-1">
                                              <b >OwnerEmail:</b>
                                            </Col>
                                            <Col>
                                              {ownerprofile[0].owner_email}
                                            </Col>
                                          </Row>
                                        </ListGroup.Item>

                                        <ListGroup.Item >
                                          <Row xs="auto">
                                            <Col className="pb-1">
                                              <b>Owner Name:</b>
                                            </Col>
                                            <Col>
                                              {ownerprofile[0].owner_name}
                                            </Col>
                                          </Row>
                                        </ListGroup.Item>

                                        <ListGroup.Item >
                                          <Row xs="auto">
                                            <Col className="pb-1">
                                              <b >PhoneNo:</b>
                                            </Col>
                                            <Col></Col>
                                            <Col>
                                              {ownerprofile[0].owner_mobile}
                                            </Col>
                                          </Row>
                                        </ListGroup.Item>

                                        <ListGroup.Item >
                                          <Row xs="auto">
                                            <Col className="pb-1">
                                              <b >AdharNo:</b>
                                            </Col>
                                            <Col></Col>
                                            <Col>
                                              {ownerprofile[0].owner_aadhar}
                                            </Col>
                                          </Row>
                                        </ListGroup.Item>

                                        <ListGroup.Item className={styles.custcarddetails}>
                                          <Row xs="auto">
                                            <Col className="pb-1">
                                              <b >Pan No:</b>
                                            </Col>
                                            <Col></Col>
                                            <Col >
                                              {ownerprofile[0].owner_pan}
                                            </Col>
                                          </Row>
                                        </ListGroup.Item>

                                      </Card.Body>
                                    </Card>
                                    <hr />
                                  </Alert>
                                }
                             
                              </div>
                            }
                          >
                          {<div class="btn btn-link"  onClick={() => ownerData(i)}>More info</div>}
                          
                          </OverlayTrigger>
                        ))}


                      </td>
                    </tr>
                  )
                }


              </table>
            </div>

          </div>
        </div>

      </div>

      {tablerow && isOpen && <Popup
        content={<>
          <div >
            <table className='mt-4' style={{ background: 'rgb(239, 238, 237)' }}>
              <tr className={table.financetable} >
                <th >Project Name</th>
                <th >Units</th>
                <th >Project Type</th>
                <th >Start Date</th>
                <th >End Date</th>
                <th>ETA</th>
                <th >Project Status</th>
              </tr>
              {
                tablerow.map((item, i) =>
                  <tr key={i} style={{ textAlign: 'center', height: 50 }}>
                    <td>{item.project_name}</td>
                    <td>{item.units}</td>
                    <td>{item.project_type}</td>
                    <td>{item.start_date}</td>
                    <td>{item.actual_end_date}</td>
                    <td>{item.date_to_complete}</td>
                    <td >{item.project_status}</td>

                  </tr>
                )}

            </table>
           
          </div>
        </>}
        handleClose={togglePopup}
      />}

    </div>



  );
}



import React from "react"
// import {Link} from 'react-router-dom'
import './Customerlogin.css';

import { useNavigate } from "react-router-dom";

import { Container } from "rsuite";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Login () {
  
  

  const [customer_email,setEmail]=React.useState('');
  const [Password,setPassword]= React.useState('');
  const handleLogin = async ()=>{

    let result = await fetch("https://lpcapp.adqservices.com/api/customer/customerlogin",{
      method:"post",
      body:JSON.stringify({customer_email,Password}),
      headers:{
        'Content-Type' : 'application/json'
      } 
    });
    // console.log(" Email !!!!!......  password!!!!!!......",result);
    result= await result.json();


    
    if (result.status === 200){    
      navigate('/CustomerHome',{state:result})
      
        }
    else if (result.status === 404){
    }  
  }

const  navigate = useNavigate();
const handleNav =  () => {
  navigate('/Customersignup')
  
}

  return (
    <div className="" style={{ color:'white', backgroundPosition:'center', backgroundRepeat:'no-repeat', backgroundSize:'cover',
     backgroundImage:
       `url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFaFF5FNVOY4EFy4HKw8I54bc5xd9oUwy6Mw&usqp=CAU")`}}>

   <Container>
   <Row>
        <Col md={{ span: 6, offset: 1 }}>
      <div className="Auth-container" >
      <form className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="form-group mt-3">
          
            <label>Email Address</label>
            < input
              type="email"
              className="form-control mt-1"
              placeholder="Enter email"
              onChange={(e)=>setEmail(e.target.value)}
              value={customer_email}
            ></input>
          </div>
          <div >
            <label> Password</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
              onChange={(e)=>setPassword(e.target.value)}
              value={Password}
            />
          </div>
          <div className="row">
          <div className="d-grid gap-2 mt-3" >
            <button type="button" className="btn btn-primary" onClick={()=>{handleLogin();}}>
              Login
            </button>
          </div>
          <div className="d-grid gap-2 mt-3" >
            <button type="submit" className="btn btn-success" onClick={handleNav}  >
              Sign up
            </button>
          </div>
          </div>
          <p className="forgot-password text-center mt-2" style={{marginLeft:140}}>
             <a href="/Forgotpassword">Forgot password?</a>
          </p>
        </div>
      </form>
    </div>

        </Col>
      </Row>
   </Container>
  
   </div>
   
  )
}



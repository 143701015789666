import React, { useEffect, useState } from 'react';
import styles from '../../Styles/Card.module.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Card, Row, Col } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
// import { useNavigate } from "react-router-dom";
import Navbar from '../../components/Navbar/Navbar'
function Salesdashboard() {

  const [salesdata, setsalesdata] = useState([])
  console.log("sss", salesdata)
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState(salesdata);
  const lendata = salesdata.sold_status
  console.log("bhbhbh", lendata)

  useEffect(() => {
    fetch("https://lpcapp.adqservices.com/api/sales/salesprojectdetails2").then((result) => {
      result.json().then((resp) => {
        setsalesdata(resp.arr2)
      })
    })
  }, [])

  console.log("dataaa", salesdata.length)

  // console.log("inactive", inactive)

  const unitsindex = salesdata.findIndex(obj => obj.project_name);
  console.log("unitssss indesxxxxxxx", unitsindex)

  const excludeColumns = ["units"];

  const handleChange = value => {
    setSearchText(value);
    filterData(value);
  };

  const filterData = (value) => {
    console.log("vaalll", value)
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setData(salesdata);

    else {
      const filteredData = salesdata.filter(item => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setData(filteredData);
    }
  }
  console.log("fffilteerr", data)

  const [hover, setHover] = useState(false);
console.log(hover)
  const handleMouseIn = (sIndex) => {
    setHover(true);
  };

  const handleMouseOut = () => {
    setHover(false);
  };

 
  const [checkedactive, setCheckedactive] = React.useState(false);

  const handleChangecheckactive = () => {
    setCheckedactive(!checkedactive);
  };

  const [checkedinactive, setCheckedinactive] = React.useState(false);

  const handleChangecheckinactive = () => {
    setCheckedinactive(!checkedinactive);
  };

  const [checkedonhold, setCheckedonhold] = React.useState(false);

  const handleChangecheckonhold = () => {
    setCheckedonhold(!checkedonhold);
  };

  return (

    <div >
      <Navbar value='salesdashboard' />

      <div className='mt-5' style={{ fontFamily: 'bold' }}>
        <Row className='container-fluid'>
          <Col>
            <Row xs="auto">
              <Col>
                <div><b>Total Projects Units</b></div>
                <div> <b>Allocated  Projects Units </b></div>
                <div> <b>Inactive  Projects Units   </b></div>
                <div>  <b>Onhold  Projects Units </b></div>
              </Col>
              <Col>
                <div><b>40</b></div>           
                <div><b >13</b></div>
                <div><b >14</b></div>
                <div> <b >13</b></div>
              </Col>

            </Row>

          </Col>
          <Col xs={6}>
            <b style={{ fontFamily: 'bold' }}>Search</b>: <input

              type="text"
              placeholder="search for projects..."
              value={searchText}
              onChange={e => handleChange(e.target.value)}
            />
          </Col>

          <Col>
            <label className='px-1'>
              <input type="checkbox" checked={checkedactive} onChange={handleChangecheckactive} />
              <b style={{ color: 'green' }}>Sold</b>
            </label>&nbsp;

            <label className='px-1'>
              <input type="checkbox" checked={checkedinactive} onChange={handleChangecheckinactive} />
              <b style={{ color: 'red' }}>Inprogess</b>
            </label>&nbsp;

            <label className='px-1'>
              <input type="checkbox" checked={checkedonhold} onChange={handleChangecheckonhold} />
              <b style={{ color: 'orange' }}>Ready To Sale</b>
            </label>&nbsp;

          </Col>
        </Row>

      </div>


      <div className=''>

        <Row className="justify-content-md-center">
          <Col md="auto">
            {data.length > 0 && <div className={styles.salescard}>
              <div className='card row' style={{ height: 400, overflow: 'auto' }}>
                <div className=''>
                  {
                    data.map((items, index) =>(

                      <div key={index}>
                        <h5 style={{ fontFamily: 'bold' }}>{items.project_name}</h5>
                        {items.units.map((subItems, sIndex) => {


                          if (salesdata[index].sold_status[sIndex] === 'On hold') {
                            return <label style={{ padding: 5 }}>

                              <div className={styles.orangecard} key={sIndex} >
                                <div className={styles.carddata}><center className='mt-2'>{subItems}</center></div>
                              </div>
                            </label>
                          }
                          else if (salesdata[index].sold_status[sIndex] === 'Allocated') {
                            return <label style={{ padding: 5 }}>

                              <div className={styles.greencard} onMouseOver={handleMouseIn} onMouseOut={handleMouseOut} key={sIndex} >

                                {['left'].map((placement) => (
                                  <OverlayTrigger
                                    trigger="hover"

                                    placement={placement}
                                    overlay={
                                      <div id={`popover-positioned-${placement}`}>
                                        <Card>
                                          <Card.Header className='customercardheader' style={{ fontFamily: 'bold', fontSize: 15, color: 'black', textAlign: 'center' }}>Customer Profile</Card.Header>
                                          <Card.Body >
                                            <ListGroup.Item className="col-12" style={{ fontSize: 14, fontFamily: 'bold' }}>Customer Name:{items.customer_name}</ListGroup.Item>
                                            <ListGroup.Item className="col-12 mt-2" style={{ fontSize: 14, fontFamily: 'bold' }}>Customer Mobile:{items.customer_mobile}</ListGroup.Item>
                                          </Card.Body>
                                        </Card>
                                      </div>
                                    }
                                  >
                                    <div className={styles.carddata}> <center className='mt-2'>{subItems}</center></div>
                                  </OverlayTrigger>
                                ))}
                              </div>
                            </label>
                          }
                          else if (salesdata[index].sold_status[sIndex] === 'Inactive') {
                            return <label style={{ padding: 5 }}>
                              <div className={styles.redcard} key={sIndex} >
                                <div className={styles.carddata}><center className='mt-2'>{subItems}</center></div>
                              </div>
                            </label>
                          }

                          else {
                            return (<div></div>);                        
                         }
                        })}

                      </div>
                    ))}
                </div>
              </div>
            </div>}
          </Col>
        </Row>


        <Row className="justify-content-md-center ">
          <Col md="auto">
            {checkedactive && data.length === 0 && <div className={styles.salescard}>
              <div className='card row' style={{ height: 250, overflow: 'auto' }}>

                {checkedactive && (

                  <div className='container card-container'>
                    {
                      salesdata.map((items, index) =>

                        <div key={index}>
                          {items.units.map((subItems, sIndex) => {

                            if (salesdata[index].sold_status[sIndex] === 'Allocated') {
                              return <div >
                                <div className='row' style={{ padding: 5 }}>
                                  <div className='col'>
                                    <div className={styles.greencard} key={sIndex} style={{ width: 150, height: 80, backgroundColor: 'green' }}>
                                      <div className={styles.carddata}>{items.project_name}</div>
                                      <div className={styles.carddata}>{subItems}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                            else {
                              return (<div></div>);
                              //return something here.
                           }
                          })}
                        </div>
                      )}
                  </div>
                )}
              </div></div>}
          </Col>
        </Row>


        <Row className="justify-content-md-center ">
          <Col md="auto">
            {checkedinactive && data.length === 0 && <div className={styles.salescard}>
              <div className='card row' style={{ height: 250, overflow: 'auto' }}>
                {checkedinactive && (

                  <div className='container card-container' >
                    {
                      salesdata.map((items, index) =>

                        <div key={index} >
                          {items.units.map((subItems, sIndex) => {

                            if (salesdata[index].sold_status[sIndex] === 'Inactive') {
                              return <label style={{ padding: 5 }}>

                                <button className={styles.redcard} key={sIndex} style={{ width: 150, height: 80, backgroundColor: 'red' }}>
                                  <div className={styles.carddata} >{items.project_name}</div>
                                  <div className={styles.carddata}>{subItems}</div>
                                </button>
                              </label>
                            }
                            else {
                              return (<div></div>);
                              //return something here.
                           }
                          })}
                        </div>
                      )}
                  </div>
                )}
              </div></div>}
          </Col>
        </Row>


        <Row className="justify-content-md-center">
          <Col md="auto">
            {checkedonhold && data.length === 0 && <div className={styles.salescard}>
              <div className='card row' style={{ height: 250, overflow: 'auto' }}>
                {checkedonhold && (

                  <div className='container card-container'>
                    {
                      salesdata.map((items, index) =>

                        <div key={index}>
                          {items.units.map((subItems, sIndex) => {

                            if (salesdata[index].sold_status[sIndex] === 'On hold') {
                              return <div style={{ padding: 5 }}>
                                <button className={styles.orangecard} key={sIndex} style={{ width: 150, height: 80, backgroundColor: 'orange' }}>
                                  <div className={styles.carddata}>{items.project_name}</div>
                                  <div className={styles.carddata}>{subItems}</div>
                                </button>
                              </div>
                            }
                            else {
                              return (<div></div>)
                              //return something here.
                           }

                          })}
                        </div>
                      )}
                  </div>
                )}
              </div></div>}
          </Col>
        </Row>


      </div>

      <div className="clearboth"></div>

      <Row className="justify-content-md-center mt-5">

        <Col md="auto">
          {data.length === 0 && !checkedactive && !checkedinactive && !checkedonhold &&
            <div className={styles.salescard}>
              <div className='card row' style={{ height: 400, overflow: 'auto' }}>
                <div className=''>
                  {
                    salesdata.map((items, index) =>

                      <div key={index}>
                        <h5 style={{ fontFamily: 'bold', color: 'black' }}>{items.project_name}</h5>
                        {items.units.map((subItems, sIndex) => {


                          if (salesdata[index].sold_status[sIndex] === 'On hold') {
                            return <label style={{ padding: 5 }}>

                              <button className={styles.orangecard} onMouseOver={handleMouseIn} onMouseOut={handleMouseOut} key={sIndex} >

                                {['left'].map((placement) => (
                                  <OverlayTrigger
                                    trigger="hover"
                                    placement={placement}
                                    overlay={
                                      <div id={`popover-positioned-${placement}`}>
                                        <Card >
                                          <Card.Header className={styles.customercardheader} style={{ fontFamily: 'bold', fontSize: 15, color: 'black', textAlign: 'center' }}>Status</Card.Header>
                                          <Card.Body >
                                            <ListGroup.Item className="col-12" style={{ fontSize: 14, fontFamily: 'bold' }}>Ready For Sale</ListGroup.Item>

                                          </Card.Body>
                                        </Card>
                                      </div>
                                    }
                                  >
                                    <div className={styles.carddata}> <center className='mt-2'>{subItems}</center></div>
                                  </OverlayTrigger>
                                ))}
                              </button>
                            </label>
                          }
                          else if (salesdata[index].sold_status[sIndex] === 'Allocated') {
                            return <label style={{ padding: 5 }}>

                              <button className={styles.greencard} onMouseOver={handleMouseIn} onMouseOut={handleMouseOut} key={sIndex} >

                                {['left'].map((placement) => (
                                  <OverlayTrigger
                                    trigger="hover"

                                    placement={placement}
                                    overlay={
                                      <div id={`popover-positioned-${placement}`}>
                                        <Card>
                                          <Card.Header className={styles.customercardheader} style={{ fontFamily: 'bold', fontSize: 15, color: 'black', textAlign: 'center' }}>Customer Profile</Card.Header>
                                          <Card.Body className={styles.custcarddetails}>
                                            <ListGroup.Item className="col-12" ><b>Customer Name:</b>{items.customer_name}</ListGroup.Item>
                                            <ListGroup.Item className="col-12 mt-2" ><b>Customer Mobile:</b> {items.customer_mobile}</ListGroup.Item>
                                          </Card.Body>
                                        </Card>
                                      </div>
                                    }
                                  >
                                    <div className={styles.carddata}> <center className='mt-2'>{subItems}</center></div>
                                  </OverlayTrigger>
                                ))}
                              </button>
                            </label>
                          }
                          else if (salesdata[index].sold_status[sIndex] === 'Inactive') {
                            return <label style={{ padding: 5 }}>
                              <button className={styles.redcard} onMouseOver={handleMouseIn} onMouseOut={handleMouseOut} key={sIndex} >

                                {['left'].map((placement) => (
                                  <OverlayTrigger
                                    trigger="hover"
                                    placement={placement}
                                    overlay={
                                      <div id={`popover-positioned-${placement}`}>
                                        <Card>
                                          <Card.Header className={styles.customercardheader} style={{ fontFamily: 'bold', fontSize: 15, color: 'black', textAlign: 'center' }}>Status</Card.Header>
                                          <Card.Body >
                                            <ListGroup.Item className="col-12" style={{ fontSize: 14, fontFamily: 'bold' }}>Unit Inprogress</ListGroup.Item>

                                          </Card.Body>
                                        </Card>
                                      </div>
                                    }
                                  >
                                    <div className={styles.carddata}> <center className='mt-2'>{subItems}</center></div>
                                  </OverlayTrigger>
                                ))}
                              </button>
                            </label>
                          }
                          else {
                            return (<div></div>);
                            //return something here.
                         }
                        })}

                      </div>
                    )}
                </div>
              </div></div>}
        </Col>

      </Row>

    </div>
  )
}
export default Salesdashboard; 

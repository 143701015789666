import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../images/Logo.jpeg';
import { useNavigate } from "react-router-dom";
import nav from '../../Styles/Navbar.module.css'

function Navbar(props) {
  const navigate = useNavigate();
  const handleNavlogout = () => {
    navigate('/Customerlogin')
  }
  return (
    <nav className="navbar navbar-expand-lg navbar-fixed-top" style={{ backgroundColor: '#8CC0DE',position:'sticky' }}>
      <div className="container-fluid">
        <a href="/" class="navbar-brand">
          <img src={logo} alt="" style={{ height: 30, width: 30, borderRadius: 30 }} />
        </a>

        <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav">
            <div className={nav.navname} >LPC</div>
            <div className={nav.navnamecenter}><div >
              {props.value === 'finance' ? 'Finance' : props.value === 'Customerhome' ? 'Customer Home' : props.value === 'projectstatus' ? 'Project Status' :
                props.value === 'salesdashboard' ? 'Sales Dashboard' : props.value === 'projectstatus' ? 'Project Status' : props.value === 'payments' ? 'Payments' :
                  props.value === 'unitbundle' ? 'Unit Bundle' : ''}</div></div>
          </div>
          <div class="navbar-nav ms-auto">
            <button className={nav.logoutbutton} onClick={handleNavlogout}>Login</button>
          </div>

        </div>
      </div>
    </nav>

  );
}
export default Navbar;
/* eslint-disable */
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Samplepayments.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import SideNavbar from '../Sidenav/SideNavbar';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import table from '../../Styles/Table.module.css';
import styles from '../../Styles/Card.module.css';
import Navbar from '../../components/Navbar/Navbar'

function App() {

  const [financeres, setfinanceresponse] = useState('');
  const { state } = useLocation();
  console.log("finnnnnnnn", state)
  const data = state.payments.paymentresult;
  const current = new Date();
  const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
  console.log("today date ", date)

  function getMonthDifference(startDate, endDate) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }
  console.log(getMonthDifference(
    new Date(date), new Date('2022-12-01'))
  );

  console.log("ggggggggggggggggggggg", getMonthDifference(new Date(date), new Date('2022-12-01')))

  const navigate = useNavigate();

  const handleNav3 = (i) => {

    console.warn("pppuuuuu", financeres)
    const amount = financeres;
    console.log("ammm", amount[i])
    console.log("iiiiiii", i)


    navigate('/paynow', { state: amount[i] })

  }
  function onFinance() {


    console.log("ddddddddd", value);
    console.log("ddddddddd111111111", value1); 
    fetch("https://lpcapp.adqservices.com/api/customer/payments", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "project_name": value,
        "units": value1
      })
    }).then((result) => {
      result.json().then((resp) => {
        setfinanceresponse(resp);
      })

    })
  }


  console.warn("payment responseee", financeres)

  const getInitialState = () => {
    const value = data[0].project_name;
    return value;
  };
  const [value, setValue] = useState(getInitialState);
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const unitsindex = data.findIndex(obj => obj.project_name === value);
  console.log("indexxxxxxxxxx", unitsindex)



  const getInitialState1 = () => {
    const value1 = data[unitsindex].units;
    return value1;
  };
  const [value1, setValue1] = useState(getInitialState1);
  const handleChange1 = (e) => {
    setValue1(e.target.value);
  };
  console.log("value11111111111111111", value1)

  if(value==="Destiny" && value1==="1D01"){
    onFinance();
  }

  return (

    <div>
   <Navbar value='payments' />

      <div >

        <div className='row row-cols-2'>
          <div className='col-6 col-md-3'>

            <SideNavbar />
          </div>
          <div className='col-12 col-md-9'>

            <div class="row mt-5">
              <div class="col">
                <div className="form-group col-md-4">
                  <div className='row'>
                    <div className='col-6'>
                    </div>
                    <div className='col-6'>
                      <div>
                        <select name="projects" className="dropdown" id="projects" defaultValue="select project" value={value} onChange={handleChange}
                        >                       
                          {data.map(({ project_name }, index) =>

                            <option value={project_name} >{project_name}</option>,
                            console.log("clickedprojecttt", { value }),
                            console.log("index", data.findIndex(obj => obj.project_name === value)),
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div className="form-group col-md-4">
                  <div className='row'>
                    <div className='col-6 '>
                    </div>
                    <div className='col-6 '>

                      <select name="projects" className="dropdown" id="units" value={value1} onChange={handleChange1}
                      >
                        <option value="0" selected>--Select units--</option>
                        {
                          data[unitsindex].units.map((option, index) =>
                            <option value={option}>{option}</option>,
                            console.log("unitsss multi", data[unitsindex].units)
                          )
                        }
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col">
                <button className="submit" onClick={onFinance} ><b>View</b></button>
              </div>
            </div>

            {financeres &&
              <div class="row mt-2" style={{ fontFamily: 'bold' }}>
                <div class="col">
                  <Card className={styles.cursor}>
                    <Card.Header className={styles.paymentcardheader}><b>Value</b></Card.Header>
                    <Card.Body >
                      <ListGroup.Item className={styles.totaldetails}>
                        ₹ {(financeres[0].unit_value).toLocaleString('en-IN')}
                      </ListGroup.Item>
                    </Card.Body>
                  </Card>
                </div>
                <div class="col">
                  <Card className={styles.cursor}>
                    <Card.Header className={styles.paymentcardheader}><b>Total Due</b></Card.Header>
                    <Card.Body >
                      <ListGroup.Item className={styles.totaldetails}>
                        ₹ {(financeres[0].total_due).toLocaleString('en-IN')}
                      </ListGroup.Item>
                    </Card.Body>
                  </Card>
                </div>

                <div class="col">
                  <Card className={styles.cursor}>
                    <Card.Header className={styles.paymentcardheader}><b>Paid</b></Card.Header>
                    <Card.Body >
                      <ListGroup.Item className={styles.totaldetails}>
                        ₹ {(financeres[0].paid_amount).toLocaleString('en-IN')}
                      </ListGroup.Item>
                    </Card.Body>
                  </Card>
                </div>

                <div class="col">
                  <Card className={styles.cursor}>
                    <Card.Header className={styles.paymentcardheader}><b>Balance</b></Card.Header>
                    <Card.Body >
                      <ListGroup.Item className={styles.totaldetails}>
                        ₹ {(financeres[0].balance_amount).toLocaleString('en-IN')}
                      </ListGroup.Item>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            }

             <center>
            <div className='container-fluid spayment row mt-3' style={{ height: 280, overflow: 'auto' }}>
              {financeres &&

                <table  >
                  <tr className={table.financetable} >
                    <th >% Payment</th>
                    <th>Stage/Component</th>
                    <th >Basic Amount</th>
                    <th >GST</th>
                    <th >TCS</th>
                    <th >Due Date</th>
                    <th >PayNow</th>
                    <th >Paid </th>
                    <th >Paid Date</th>
                    <th >Balance</th>
                  </tr>
                  {
                    financeres.map((item, i) =>

                      <tr key={i} className={table.fintr}>

                        <td>{item.payment_percentage}%</td>
                        <td>{item.stage}</td>
                        <td>
                          ₹ {(item.basic_amount).toLocaleString('en-IN')}
                        </td>
                        <td>
                          ₹ {(item.gst).toLocaleString('en-IN')}
                        </td>
                        <td>
                          ₹ {(item.tcs).toLocaleString('en-IN')}
                        </td>
                        <td>{item.due_date}</td>
                        <td> <button type="button" class="btn btn-link"
                          disabled={item.balance == 0 || 
                            (getMonthDifference(new Date(date), new Date(`${new Date(item.due_date).getFullYear()}-${new Date(item.due_date).getMonth() + 1}-${new Date(item.due_date).getDate()}`)) > 1 && (!item.balance == 0))}
                          onClick={() => handleNav3(i)}>Pay</button> </td>

                        <td>
                          { !item.paid =="" &&
                            <td> ₹ {(item.paid).toLocaleString('en-IN')} </td>}
                        </td>
 
                        <td>
                          {!item.paid_date =="" &&
                            <td>  {(item.paid_date).toLocaleString('en-IN')} </td>}
                        </td>

                        {
                          <td>₹ {(item.balance).toLocaleString('en-IN')} </td>}
                      </tr>
                    )
                  }

                </table>
              }

            </div>
            </center>
            {financeres && <div className='mt-3'>
              <span style={{ color: 'red' }}>* </span><b style={{ fontFamily: 'bold' }}>The amount has included with GST(%)</b>
              </div>}
          </div>

        </div>
      </div>
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Popup from '../Popup';
import 'bootstrap/dist/css/bootstrap.min.css';
import SideNavbar from '../Sidenav/SideNavbar';
import table from '../../Styles/Table.module.css';
import styles from '../../Styles/Card.module.css';
import main from '../../Styles/Main.module.css';
import Navbar from '../../components/Navbar/Navbar'


function App() {

  const [isOpen, setIsOpen] = useState(false);
  const [loandata, setloandata] = useState('');
  // const [declineloandata, setdeclineloandata] = useState('');
  const { state } = useLocation();
  const data = state.finance.finresult;

  const togglePopup = (i) => {
    setIsOpen(!isOpen);
    // const toggledata = data;
  
    const projectloan = data[i].project_name
  
    const unitloan = data[i].units

    fetch("https://lpcapp.adqservices.com/api/customer/bank_loan_finance", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "project_name": projectloan,
        "project_unit": unitloan
      })
    }).then((result) => {
      result.json().then((resp) => {
        setloandata(resp);
      })

    })
    
  }
  console.log(" Approvedn n loannn data", loandata)

  // const togglePopupdecline = (i) => {
  //   setIsOpen(!isOpen);
  //   const toggledatadecline = data;
    
  //   const projectloan = data[i].project_name
    
  //   const unitloan = data[i].units
   
  //   fetch("https://lpcapp.adqservices.com/api/customer/decline_loan_finance", {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       "project_name": projectloan,
  //       "project_unit": unitloan
  //     })
  //   }).then((result) => {
  //     result.json().then((resp) => {
  //       setdeclineloandata(resp);
  //     })

  //   })
  // }
  
  // let status ="";
  

  return (

    <div>
      
      <Navbar value='finance' />
      <div class="">
        <div className='row row-cols-2'>
          <div className='col-6 col-md-3'>

            <SideNavbar />
          </div>
          <div className='col-12 col-md-9 '>
            <center>
              <table className={table.projectstatustable}>

                <tr className={table.financetable} >

                  <th >Project Name</th>
                  <th >Units</th>
                  <th >Bank Name</th>
                  <th >Bank Location</th>
                  <th >Rate of Interest</th>
                  <th >Loan affairs</th>
                
                </tr>
                {
                  data.map((item, i) =>
                    <tr key={i} className={table.fintr}>
                      <td>{item.project_name}</td>
                      <td>{item.units}</td>
                      <td>{item.bank_name}</td>
                      <td>{item.bank_location}</td>
                      <td>{item.rate_of_interest}</td>
                      <button type="button" class="btn btn-link" disabled={item.loan_status === "Decline"}
                      value="Click to Open Popup" onClick={() => togglePopup(i)}>{item.loan_status}
                    </button>
               
                      {/* <button type="button" class="btn btn-link"
                        value="Click to Open Popup"   
                      
                        onClick={() => {
                          if (item.loan_status == "Approved"  ) {
                            status = item.loan_status
                            console.log("ssssttt",status)
                          togglePopup(i)
                          }

                          else if (item.loan_status == "Decline"){
                            status = item.loan_status
                            console.log("decline ssstttt",status)
                          togglePopupdecline(i)
                            
                          }
                        }
                      
                      }

                      >{item.loan_status}

                      </button>
                       */}
                    </tr>
                  )}

              </table>
            </center>

          </div>

        </div>

   {    <div className='row' >
          {loandata   && isOpen &&  <Popup
            content={<>
              <div className='row justify-content-center'>
                <div className='col-4'>
                  {
                    loandata.map((item) =>
                    <Card  style={{width:400,fontFamily:'cursives',cursor:'default'}}>
                        <Card.Header className={styles.paymentcardheader}>Loan Info</Card.Header>
                        <Card.Body >
                          <ListGroup.Item className={styles.custcarddetails}>
                            <div className='row'>
                              <div className='col-sm-6 pb-1'>
                                <b className='custcarddetails'>Name</b>
                              </div>
                              <div className='col-sm-1'>:</div>
                              <div className='col-sm-5'>
                                {item.holder_name},

                              </div> 
                            </div>
                           
                          </ListGroup.Item>

                          <ListGroup.Item className='custcarddetails'>
                        
                            <div className='row'>
                              <div className='col-sm-6 pb-1'>
                                <b className='custcarddetails'>Bank Name:</b>
                              </div>
                              <div className='col-sm-1'>:</div>
                              <div className='col-sm-5'>
                                {item.bank_name}
                              </div>
                            </div>
                          </ListGroup.Item>

                          <ListGroup.Item className='custcarddetails'>
                            <div className='row'>
                              <div className='col-sm-6 pb-1'>
                                <b className='custcarddetails'>Ac Number:</b>
                              </div>
                              <div className='col-sm-1'>:</div>
                              <div className='col-sm-5'>
                                {item.account_number}
                              </div>
                            </div>
                            
                          </ListGroup.Item>

                          <ListGroup.Item className='custcarddetails'>
                            <div className='row'>
                              <div className='col-sm-6 pb-1'>
                                <b className='custcarddetails'>Rate of Interest:</b>
                              </div>
                              <div className='col-sm-1'>:</div>
                              <div className='col-sm-5'>
                                {item.rate_of_interest}
                              </div>
                            </div>
                          
                          </ListGroup.Item>

                          <ListGroup.Item className='custcarddetails'>
                            <div className='row'>
                              <div className='col-sm-6 pb-1'>
                                <b className='custcarddetails'>Location:</b>
                              </div>
                              <div className='col-sm-1'>:</div>
                              <div className='col-sm-5'>
                                {item.bank_location}
                              </div>
                            </div>
                           
                          </ListGroup.Item>

                          <ListGroup.Item className='custcarddetails'>
                            <div className='row'>
                              <div className='col-sm-6 pb-1'>
                                <b className='custcarddetails'>Applied Amount:</b>
                              </div>
                              <div className='col-sm-1'>:</div>
                              <div className='col-sm-5'>
                                ₹ {(item.applied_amount).replace(/(\d)(?=(\d\d)+$)/g, "$1,")}
                                
                              </div>
                            </div>
                   
                          </ListGroup.Item>

                          <ListGroup.Item className='custcarddetails'>
                            <div className='row'>
                              <div className='col-sm-6 pb-1'>
                                <b className='custcarddetails'>Sancation Amount:</b>
                              </div>
                              <div className='col-sm-1'>:</div>
                              <div className='col-sm-5' >
                                <div className={main.numberformat}> ₹ {(item.loan_sancation_amount).replace(/(\d)(?=(\d\d)+$)/g, "$1,")}</div>
                              </div>
                            </div>
                        
                          </ListGroup.Item>

                          <ListGroup.Item className='custcarddetails'>
                            <div className='row'>
                              <div className='col-sm-6 pb-1'>
                                <b className='custcarddetails'>Dispersed Amount:</b>
                              </div>
                              <div className='col-sm-1'>:</div>
                              <div className='col-sm-5'>
                                <div className={main.numberformat}> ₹ {(item.dispersed_amount).replace(/(\d)(?=(\d\d)+$)/g, "$1,")}</div>

                              </div>
                            </div>
                         
                          </ListGroup.Item> 
                        </Card.Body>
                      </Card>
                    )
                  }
                </div>
              </div>
            </>}
            handleClose={togglePopup} 
          />}
        </div>}

       {/* {<div className='row' >
          {declineloandata && isOpen &&<Popup
            content={<>
              <div className='row justify-content-center'>
                <div className='col-4'>
                  {
                    declineloandata.map((item) =>
                     <Card style={{width:400,fontFamily:'cursives',cursor:'default'}}>
                        <Card.Header className={styles.paymentcardheader}>Loan Info</Card.Header>
                        <Card.Body >
                          <ListGroup.Item className={styles.custcarddetails}>
                            <div className='row'>
                              <div className='col-sm-6 pb-1'>
                                <b className='custcarddetails'>Name:</b>
                              </div>
                              <div className='col'>
                                {item.holder_name}
                              </div>
                            </div>
                   
                          </ListGroup.Item>

                          <ListGroup.Item className='custcarddetails'>
                        
                            <div className='row'>
                              <div className='col-sm-6 pb-1'>
                                <b className='custcarddetails'>Bank Name:</b>
                              </div>
                              <div className='col'>
                                {item.bank_name}
                              </div>
                            </div>
                          </ListGroup.Item>

                          <ListGroup.Item className='custcarddetails'>
                            <div className='row'>
                              <div className='col-sm-6 pb-1'>
                                <b className='custcarddetails'>Ac Number:</b>
                              </div>
                              <div className='col'>
                                {item.account_number}
                              </div>
                            </div>
                           
                          </ListGroup.Item>

                          <ListGroup.Item className='custcarddetails'>
                            <div className='row'>
                              <div className='col-sm-6 pb-1'>
                                <b className='custcarddetails'>Rate of Interest:</b>
                              </div>
                              <div className='col'>
                                {item.rate_of_interest}
                              </div>
                            </div>
 
                          </ListGroup.Item>

                          <ListGroup.Item className='custcarddetails'>
                            <div className='row'>
                              <div className='col-sm-6 pb-1'>
                                <b className='custcarddetails'>Location:</b>
                              </div>
                              <div className='col'>
                                {item.bank_location}
                              </div>
                            </div>
  
                          </ListGroup.Item>

                          <ListGroup.Item className='custcarddetails'>
                            <div className='row'>
                              <div className='col-sm-6 pb-1'>
                                <b className='custcarddetails'>Applied Amount:</b>
                              </div>
                              <div className='col'>
                                ₹ {(item.applied_amount).replace(/(\d)(?=(\d\d)+$)/g, "$1,")}
                     
                              </div>
                            </div>
                        
                          </ListGroup.Item>



                          <ListGroup.Item className='custcarddetails'>
                            <div className='row'>
                              <div className='col-sm-6 pb-1'>
                                <b className='custcarddetails'>Remarks:</b>
                              </div>
                              <div className='col'>
                                {item.remarks}
                              </div>
                            </div>
                     
                          </ListGroup.Item>

                        </Card.Body>
                      </Card>
                    )
                  }
                </div>
              </div>
            </>}
            handleClose={togglePopupdecline}
          />}
        </div>} */}

      </div>
    </div>
  );
}

export default App;

import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export default function FormValidation() {

    const navigate = useNavigate();
    const [customer_name, setcustomername] = React.useState('');
    const [customer_fullname, setFullname] = React.useState('');
    const [customer_email, setcustomeremail] = React.useState('');
    const [customer_mobile, setcustomermobile] = React.useState('');
    const [Password, setPassword] = React.useState('');
    const [confirm_password, setconfirmPassword] = React.useState('');

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async ()  => {
        let result = await fetch('192.168.100.11:3105/customer/customersignup', {
            method: 'post',
            body: JSON.stringify({ customer_name, customer_fullname, customer_email, customer_mobile, Password,confirm_password }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        result = await result.json();
        console.warn(result);
     navigate('/Customersignup')
     if (result.status === 404){
        alert("Email/Mobile Number already exists");
        }
        else if (result.status === 500){
            alert("Please enter all the required fields")
          } 
          else if (result.status === 450){
            alert("Password and Confirm password doesnot match")
          }
    }
    return (
        <div>
            <div className="row" style={{marginTop:60}}> 
            <div className='col-3'></div>
            <div className='col-6'>   
            <Form onSubmit={handleSubmit(onSubmit)} className="Auth-form1">
            <div className="Auth-form-content">
             <h3 className="Auth-form-title1" style={{fontWeight:700}}>Sign up</h3>
             <h6 style={{fontSize:13}}>It's quick and easy.</h6>
            <div className="form-group mt-3">
                <Form.Field>
                    <div className='row'>
                        <div className='col-3'>
                        <label style={{color:"#00008b"}}>First Name<sup style={{color:'red'}}>*</sup>:</label><br/>
                        </div>
                        <div className='col-9'>
                        <input
                        placeholder='First Name'
                        className="form-control mt-1"
                        type="text"
                        {...register("customer_name", { required: true, maxLength: 10 })}
                        onChange={(e) => setcustomername(e.target.value)}
                        value={customer_name}
                    />
                        </div>
                    </div>
                   
                </Form.Field>
                </div>
                {errors.customer_name && <div style={{color:'red'}}>Please enter the First Name</div>}

                <div className="form-group mt-3">
                <Form.Field>
                <div className='row'>
                        <div className='col-3'>
                        <label style={{color:"#00008b"}}>Last Name<sup style={{color:'red'}}>*</sup>:</label><br/>
                        </div>
                        <div className='col-9'>
                        <input
                        placeholder='Last Name'
                        className="form-control mt-1"
                        type="text"
                        {...register("customer_fullname", { required: true, maxLength: 10 })}
                        onChange={(e) => setFullname(e.target.value)}
                        value={customer_fullname}
                    />
                        </div>
                    </div>
                    
                  
                </Form.Field>
                </div>
                {errors.customer_fullname && <div style={{color:'red'}}>Please enter the Last Name</div>}

                <div className="form-group mt-3">
                <Form.Field>
                <div className='row'>
                        <div className='col-3'>
                        <label style={{color:"#00008b"}}>Email<sup style={{color:'red'}}>*</sup>:</label><br/>
                        </div>
                        <div className='col-9'>
                        <input
                        placeholder='Email'
                        className="form-control mt-1"
                        type="email"
                        {...register("customer_email",
                            {
                                required: true,
                                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            })}
                            onChange={(e) => setcustomeremail(e.target.value)}
                            value={customer_email}
                    />
                        </div>
                    </div>
                 
                  
                </Form.Field>
                </div>
                {errors.customer_email && <div style={{color:'red'}}>Please enter the Email</div>}

                <div className="form-group mt-3">
                <Form.Field>
                <div className='row'>
                        <div className='col-3'>
                        <label style={{color:"#00008b"}}>Mobile Number<sup style={{color:'red'}}>*</sup>:</label><br/>
                        </div>
                        <div className='col-9'>
                        <input
                        placeholder='Mobile Number'
                        className="form-control mt-1"
                        type="mobilenumber"
                        {...register("customer_mobile",
                            {
                                required: true,
                                pattern: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i
                            })}
                            onChange={(e) => setcustomermobile (e.target.value)}
                            value={customer_mobile}
                    />
                        </div>
                </div>                 
                </Form.Field>
                </div>
                {errors.customer_mobile && <div style={{color:'red'}}>Please enter the Email</div>}

                <div className="form-group mt-3">
                <Form.Field>
                <div className='row'>
                        <div className='col-3'>
                        <label style={{color:"#00008b"}}>Password<sup style={{color:'red'}}>*</sup>:</label><br/>
                        </div>
                        <div className='col-9'>
                        <input
                        placeholder='Password'
                        className="form-control mt-1"
                        type="password"
                        {...register("password", {
                            required: true,
                        })}
                        onChange={(e) => setPassword(e.target.value)}
                            value={Password}
                    />
                        </div>
                    </div>
                   
                   
                </Form.Field>
                </div>
                {errors.password && <div style={{color:'red'}}>Please enter the Password</div>}

                <div className="form-group mt-3">
                <Form.Field>
                <div className='row'>
                        <div className='col-3'>
                        <label style={{color:"#00008b"}}>Confirm Password<sup style={{color:'red'}}>*</sup>:</label><br/>
                        </div>
                        <div className='col-9'>
                        <input
                        placeholder='Confirm Password'
                        className="form-control mt-1"
                        type="confirm password"
                        {...register("confirmpassword", {
                            required: true,
                        })}
                        onChange={(e) => setconfirmPassword(e.target.value)}
                            value={confirm_password}
                    />
                        </div>
                    </div>
                 
                </Form.Field>
                </div>
                {errors.confirmpassword && <div style={{color:'red'}}>Please enter the Confirm Password</div>}
                <div className="row">
                    <div className="col-5"></div>
                    <div className="col-2">
                    <Button className='btn btn-success mt-3' type='submit' style={{textAlign:'center'}} >Submit</Button>
                    </div>
                    <div className="col-5"></div>
                </div>
                </div>
            </Form>
            </div>
            <div className='col-3'></div>
        </div>
        </div>
     
    )
}